export const NODE_ENV = {
  DEV: "development",
  TEST: "test",
  PROD: "production",
} as const;

export const ONE_KB = 1_000;

export const FIVE_MB = 5_000_000;

export const TEN_MB = 10_000_000;

export const ONE_HUNDRED_MB = 100_000_000;

export const ONE_GB = 1_000_000_000;

export const ImageExtension = {
  PNG: ".png",
  JPG: ".jpg",
  JPEG: ".jpeg",
  WEBP: ".webp",
  SVG: ".svg",
  HEIC: ".heic",
  HEIF: ".heif",
} as const;

export const VideoExtension = {
  MP4: ".mp4",
  AVI: ".avi",
  MOV: ".mov",
  WMV: ".wmv",
  FLV: ".flv",
  MKV: ".mkv",
  WEBM: ".webm",
} as const;

export const FileType = {
  PNG: { mime: "image/png", extensions: [ImageExtension.PNG] },
  HEIC: { mime: "image/heic", extensions: [ImageExtension.HEIC] },
  HEIF: { mime: "image/heif", extensions: [ImageExtension.HEIF] },
  JPG: {
    mime: "image/jpeg",
    extensions: [ImageExtension.JPEG, ImageExtension.JPG],
  },
  SVG: { mime: "image/svg+xml", extensions: [ImageExtension.SVG] },
  WEBP: { mime: "image/webp", extensions: [ImageExtension.WEBP] },
  MP4: { mime: "video/mp4", extensions: [VideoExtension.MP4] },
  AVI: { mime: "video/x-msvideo", extensions: [VideoExtension.AVI] },
  MOV: { mime: "video/quicktime", extensions: [VideoExtension.MOV] },
  WMV: { mime: "video/x-ms-wmv", extensions: [VideoExtension.WMV] },
  FLV: { mime: "video/x-flv", extensions: [VideoExtension.FLV] },
  MKV: { mime: "video/x-matroska", extensions: [VideoExtension.MKV] },
  WEBM: { mime: "video/webm", extensions: [VideoExtension.WEBM] },
} as const;

export const DefaultThumnailSize = {
  WIDTH: 340,
  HEIGHT: 450,
};
